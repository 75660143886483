<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-form-group label="User ID" label-for="user_id">
          <b-form-input
            v-model="dataForm.user_id"
            id="user_id"
            type="text"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Balance" label-for="balance-min">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="dataForm.balance_min"
              id="balance-min"
              type="number"
              class="mr-1"
              placeholder="MIN"
            />
            -
            <b-form-input
              v-model="dataForm.balance_max"
              id="balance-max"
              type="number"
              class="ml-1"
              placeholder="MAX"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Type Currency" label-for="currency">
          <b-form-select
            v-model="dataForm.currency"
            id="currency"
            :options="currency_options"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="mb-2 d-flex justify-content-end">
      <b-button @click="search" class="mt-0 mt-md-2 mr-1" variant="primary">
        Filter
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataForm: {
        currency: null
      },
      balance_min: null,
      balance_max: null,
      currency_options: [
        {text: "All", value: null},
        {text: "Gem", value: 1},
        {text: "GOGA Token", value: 2}
      ]
    };
  },
  methods: {
    search() {
      this.$emit("getList", this.dataForm);
    },
  },
};
</script>
