<template>
    <div class="page-content">
        <b-row>
            <b-col md="10">
                <page-breadcrumb title="User Balance" class="mb-3" />
            </b-col>
        </b-row>
        <b-media>
            <section class="py-1">
                <h2 class="mb-2">Balance Management</h2>
                <div class="mb-2 d-flex justify-content-end">
                    <b-button @click="exportData" class="mt-0 mt-md-2 mr-1" variant="success">
                        Export
                    </b-button>
                </div>
                <user-balance-filter @getList="getList" />
                <b-card>
                <user-balance-table
                    @getList="getList"
                    :items="userBalances"
                    :is-loading="isLoading"
                />
                </b-card>
            </section>
        </b-media>
        <b-pagination
            class="mt-auto"
            pills
            size="lg"
            align="center"
            v-model="current_page"
            :total-rows="total_items"
            :per-page="items_perpage"
        />
    </div>
</template>
<script>
import service from "../service"
import UserBalanceTable from "./_components/UserBalanceTable.vue"
import UserBalanceFilter from "./_components/UserBalanceFilter.vue"
import XLSX from "xlsx"
export default {
    components:{
        UserBalanceTable,
        UserBalanceFilter
    },
    data(){
        return {
            userBalances: [],
            total_items: 0,
            current_page: 1,
            items_perpage: 50,
            isLoading: true,
            export_data: [],
            query: {}
        }
    },
    watch: {
        current_page() {
            this.getList();
        }
    },
    created(){
        this.getList();
    },
    methods: {
        async getList(query = {}) {
            let response = await service.getList({
                page: this.current_page,
                limit: this.items_perpage,
                query: JSON.stringify(query)
            });
            this.query = query;
            if (response.data.type === "DATA") {
                this.userBalances = response.data.data.list;
                this.total_items = response.data.data.total;
            }
            this.isLoading = false
        },
        async exportData(){
            let response = await service.getAll({query: JSON.stringify(this.query)});
            if (response.data.type === "DATA") {
                this.export_data = response.data.data.list;
            }
            this.export_data = this.export_data.map((result)=>{
                return {
                    user_id: result.user_id,
                    currency: this.convertCurrency(result.currency),
                    balance: result.balance,
                    frz_balance: result.frz_balance,
                    flt_balance: result.flt_balance,
                    available_balance: result.balance - result.frz_balance
                }
            })
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.json_to_sheet(this.export_data, { origin: 'A2', skipHeader: true });
            const header = [['USER ID', 'CURRENCY', 'BALANCE', 'FROZEN BALANCE', 'UPCOMMING BALANCE', 'AVAILABLE BALANCE']];
            XLSX.utils.sheet_add_aoa(ws, header);
            XLSX.utils.book_append_sheet(wb, ws, 'Data');
            XLSX.writeFile(wb, `user_balance.xlsx`);
            this.export_results = [];
        },
        convertCurrency(value){
            switch (value){
                case 1: return "Gem"
                case 2: return "GOGA Token"
                default: return "Unknown"
            }
        },
    }
}
</script>