<template>
    <div>
        <b-table :items="items" :fields="fields" />
    </div>
</template>
<script>
export default {
    props:{
        items: {type: Array, default: null},
        is_loading: {type: Boolean, default: true}
    },
    data(){
        return {
            fields: [
                {
                    key: "user_id",
                    label: "User id",
                    class: 'text-center'
                },
                {
                    key: "currency",
                    label: "Currency",
                    formatter: value => {
                        return this.convertCurrency(value)
                    },
                    class: 'text-center'
                },
                {
                    key: "balance",
                    label: "Balance",
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: "frz_balance",
                    label: "Frozen Balance",
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: "flt_balance",
                    label: "Upcomming Balance",
                    sortable: true,
                    class: 'text-center'
                },
                {
                    key: "available_balance",
                    label: "Available balance",
                    formatter: (value, key, item) =>{
                        return this.availableBalance(item.balance, item.frz_balance)
                    },
                    sortable: true,
                    sortByFormatted: true,
                    class: 'text-center'
                },
            ]
        }
    },
    methods: {
        convertCurrency(value){
            switch (value){
                case 1: return "Gem"
                case 2: return "GOGA Token"
                default: return "Unknown"
            }
        },
        availableBalance(balance, frz_balance){
            return balance - frz_balance
        }
    }
}
</script>